import React from "react";
import Img from "gatsby-image";
import styled from "styled-components";
import { Link } from "gatsby";
import {
  useRemoveItemFromCart,
  useEditItemQuantityInCart,
} from "../context/StoreContext";

const CardContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-gap: 20px;
  margin-bottom: 50px;
  @media (max-width: 900px) {
    grid-template-columns: 100px 1fr;
    margin-bottom: 30px;
  }
`;

const CartItemImgLink = styled(Link)`
  width: 200px;
  height: 200px;
  @media (max-width: 900px) {
    width: 100px;
    height: 100px;
  }
`;

const CartItemImg = styled(Img)`
  width: 200px;
  height: 200px;
  background-color: white;
  object-fit: cover;
  @media (max-width: 900px) {
    width: 100px;
    height: 100px;
  }
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Title = styled(Link)`
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  margin: 0;
  padding: 10px;
  text-decoration: none;
  color: black;
  border: 1px solid black;
  box-sizing: border-box;
  @media (max-width: 900px) {
    font-size: 12px;
  }

  transition: color 0.1s ease;

  &:hover {
    color: #0000ff;
  }
`;

const ProductDetailsLabels = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  margin: 20px 0 15px 0;

  @media (max-width: 700px) {
    margin: 10px 0 10px 0;
  }
`;

const ProductDetails = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  margin: 0 0 0 0;
`;

const Label = styled.p`
  font-size: 12px;
  color: black;
  justify-self: center;
  @media (max-width: 900px) {
    font-size: 12px;
  }
`;

const Value = styled.p`
  font-size: 12px;
  color: black;
  justify-self: center;
  margin: 0 0 0 0;
  text-transform: uppercase;
  @media (max-width: 900px) {
    font-size: 12px;
  }
`;

const RemoveContainer = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  @media (max-width: 700px) {
    height: 30px;
  }
`;

const RemoveButton = styled.button`
  background-color: white;
  font-size: 12px;
  border: none;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: #0000ff;
  }

  @media (max-width: 900px) {
    font-size: 12px;
  }
`;

const EditButton = styled.button`
  background-color: white;
  font-size: 12px;
  border: none;
  text-decoration: none;
  cursor: pointer;
  margin: 0 5px 0 5px;
  outline: none;

  &:hover {
    color: #0000ff;
  }

  @media (max-width: 900px) {
    font-size: 12px;
  }
`;

const QuantityWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: fit-content;
  justify-self: center;
  align-items: center;
`;

const CartProduct = ({ title, quantity, image, size, price, id, handle }) => {
  const removeFromCart = useRemoveItemFromCart();
  const EditLineItem = useEditItemQuantityInCart();
  const getPrice = (price, quantity) => {
    return parseInt(price) * parseInt(quantity);
  };

  const plusQuantity = parseInt(quantity) + 1;
  const minusQuantity = parseInt(quantity) - 1;

  return (
    <CardContainer>
      <CartItemImgLink to={`/product/${handle}`}>
        <CartItemImg fluid={image} />
      </CartItemImgLink>
      <DetailsContainer>
        <Title to={`/product/${handle}`}>{title}</Title>
        <ProductDetailsLabels>
          <Label>PRICE</Label>
          {size.toLowerCase() !== "default title" ? <Label>SIZE</Label> : <></>}
          <Label>QUANTITY</Label>
        </ProductDetailsLabels>
        <ProductDetails>
          <Value>${getPrice(price, quantity).toFixed(2)}</Value>
          {size.toLowerCase() !== "default title" ? (
            <Value>{size}</Value>
          ) : (
            <></>
          )}

          <QuantityWrapper>
            <EditButton onClick={() => EditLineItem(id, minusQuantity)}>
              -
            </EditButton>
            <Value>{quantity}</Value>
            <EditButton onClick={() => EditLineItem(id, plusQuantity)}>
              +
            </EditButton>
          </QuantityWrapper>
        </ProductDetails>
        <RemoveContainer>
          <RemoveButton onClick={() => removeFromCart(id)}>REMOVE</RemoveButton>
        </RemoveContainer>
      </DetailsContainer>
    </CardContainer>
  );
};

export default CartProduct;
