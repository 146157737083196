import React from "react";
import styled from "styled-components";
import {
  useCartItems,
  useCartTotals,
  useCheckout,
} from "../context/StoreContext";
import { useStaticQuery, graphql } from "gatsby";
import { Link } from "gatsby";
import CartProductCard from "../components/CartProductCard.js";

const PageWrapper = styled.div`
  max-width: 1400px;
  width: calc(100% - 300px);
  margin: 40px 150px 100px 150px;
  display: grid;
  grid-template-columns: 270px 1fr;
  grid-template-areas: "checkout items";
  grid-gap: 40px;

  @media (max-width: 1200px) {
    grid-template-columns: 230px 1fr;
  }

  @media (max-width: 1000px) {
    width: calc(100% - 40px);
    margin: 150px auto 100px auto;
  }

  @media (max-width: 700px) {
    grid-template-columns: 1fr;
    grid-gap: 0;
    grid-template-areas: "items" "checkout";
    margin-bottom: 150px;
  }

  @media (max-width: 500px) {
    margin: 120px auto 150px auto;
  }

  @media (min-width: 1400px) {
    grid-gap: 120px;
  }

  @media (min-width: 1600px) {
    margin: 40px auto 100px auto;
  }
`;

const CheckoutContainer = styled.div`
  grid-area: checkout;
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 40px;
  left: 150px;
  margin: 0;

  @media (max-width: 1000px) {
    left: 20px;
    top: 150px;
  }

  @media (max-width: 700px) {
    position: fixed;
    z-index: 10;
    width: 250px;
    top: auto;
    bottom: 20px;
    left: calc(50% - 125px);
  }

  @media (max-width: 350px) {
    position: fixed;
    z-index: 10;
    width: 200px;
    top: auto;
    bottom: 20px;
    left: calc(50% - 100px);
  }
`;

const Subtotal = styled.div`
  height: fit-content;
  width: 100%;
  border: 1px solid black;
  background: white;
  box-sizing: border-box;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  @media (max-width: 500px) {
    font-size: 12px;
  }
`;

const SubtotalTag = styled.p`
  margin-left: 20px;
  width: 100%;
  padding: 15px 0 15px 0;
`;

const SubtotalPrice = styled.p`
  margin-right: 20px;
  width: fit-content;
  padding: 15px 0 15px 0;
`;

const Checkout = styled.button`
  text-align: center;
  font-size: 14px;
  text-align: center;
  width: 100%;
  background-color: #0000ff;
  color: white;
  border: none;
  padding: 15px 0 15px 0;
  margin: 10px 0 0 0;
  box-sizing: content-box;
  outline: none;
  cursor: pointer;

  @media (max-width: 500px) {
    font-size: 12px;
  }
`;

const ItemsContainer = styled.div`
  grid-area: items;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0;

  @media (max-width: 900px) {
    position: static;
    width: 100%;
    align-items: center;
    right: 0;
  }

  @media (min-width: 1400px) {
    width: 100%;
  }
`;

const EmptyContainer = styled.div`
  position: absolute;
  z-index: 1;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: black;
  margin: 0;
  text-decoration: none;

  @media (max-width: 1000px) {
    height: calc(var(--vh, 1vh) * 100);
  }

  @media (max-width: 500px) {
    font-size: 14px;
  }
`;

const EmptyLink = styled(Link)`
  font-size: 16px;
  color: #0000ff;
  margin: 10px 0 0 0;
  text-decoration: none;

  @media (max-width: 500px) {
    font-size: 14px;
  }
`;

const CartPage = () => {
  const {
    allShopifyProductVariant: { nodes: variants },
    allShopifyProduct: { nodes: products },
  } = useStaticQuery(graphql`
    query {
      allShopifyProductVariant {
        nodes {
          shopifyId
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1000) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
      allShopifyProduct {
        nodes {
          handle
          variants {
            shopifyId
          }
        }
      }
    }
  `);

  const lineItems = useCartItems().lineItems;
  const { total } = useCartTotals();
  const checkout = useCheckout();

  function getImageFluidForVariant(variantId) {
    const selectedVariant = variants.find((variant) => {
      return variant.shopifyId === variantId;
    });

    if (selectedVariant) {
      return selectedVariant.image.localFile.childImageSharp.fluid;
    }
    return null;
  }

  return (
    <PageWrapper>
      {lineItems.length !== 0 ? (
        <CheckoutContainer>
          <Subtotal>
            <SubtotalTag>SUBTOTAL:</SubtotalTag>
            <SubtotalPrice>{total}</SubtotalPrice>
          </Subtotal>
          <Checkout onClick={checkout}>CHECKOUT</Checkout>
        </CheckoutContainer>
      ) : (
        <EmptyContainer>
          EMPTY CART <br></br>
          <EmptyLink to="/collection/all">SHOP ALL</EmptyLink>
        </EmptyContainer>
      )}
      <ItemsContainer>
        {lineItems.map((item) => (
          <>
            <CartProductCard
              title={item.title}
              quantity={item.quantity}
              handle={item.variant.product.handle}
              image={getImageFluidForVariant(item.variant.id)}
              size={item.variant.title}
              price={item.variant.priceV2.amount}
              id={item.id}
            />
          </>
        ))}
      </ItemsContainer>
    </PageWrapper>
  );
};

export default CartPage;
